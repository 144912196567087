$(window).on("load",function(){
});
$(function(){

    var $body = $('body');

	//SVGスプライト
	$.ajax({
		type: 'get',
		url: '/wp/wp-content/themes/fieldhead/assets/svg/icons.svg'//ここにパスを入れる（環境に合わせて変更）
	}).done(function(data) {
		var svg = $(data).find('svg');
		$body.prepend(svg);
	});

    //ページ内スクロール
    $.smooziee();

    //スクロールしたらクラスを付与
    $(window).scroll(function () {
        if ($(this).scrollTop() > 300) {
            $body.addClass("scroll");
        } else {
            $body.removeClass("scroll");
        }
    });

    //開閉用ボタンをクリックでクラスの切替え
    $('.header_sp_menu').on('click', function () {
        $body.toggleClass('open');
    });

    //メニュー名以外の部分をクリックで閉じる
    $('.layer').on('click', function () {
        $body.removeClass('open');
    });

    //検索窓の開閉
    // $('.s_search_title').on('click', function () {
    //     $(this).toggleClass('open');
    //     $(this).next("div").toggleClass('open');
    //     $(this).next("div").slideToggle();
    // });

    //PC・スマホ切り替え
    $(window).on('load resize', function(){
        var w = $(window).width();
        if (w <= 768) {
            //スマホの場合
            // $('.s_search_title').removeClass('open');
            // $('.s_search_title').next("div").removeClass('open');
            // $('.s_search_title').next("div").slideUp();
        } else {
            //PCの場合
            // $('.s_search_title').addClass('open');
            // $('.s_search_title').next("div").addClass('open');
            // $('.s_search_title').next("div").slideDown();
        }
    });

    const swiper = new Swiper('.visual_slide', {
        // Optional parameters
        // direction: 'vertical',
        loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
    });

    $('.movie-thumb').on('click', function(){
        videoControl('playVideo',$(this).prev('iframe'));
        $(this).hide();
    });
    function videoControl(action,tgt){
        var $playerWindow = $(tgt)[0].contentWindow;
        $playerWindow.postMessage('{"event":"command","func":"'+action+'","args":""}', '*');
    }

});
